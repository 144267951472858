import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div id="text">
      <h1><span className="color">Andie</span> Thompson</h1>
      <p><span className="color">PhD student</span> at <strong>UvA</strong> & <strong>OSU</strong> ... <strong>♀</strong> she/her</p>
      <p><a href="//twitter.com/andieohoh">Connect on Twitter</a> | <a href="//globalfuturehealth.org">FutureHealth Project</a></p>
      <p><span className="color">Anthropologist</span><br/><strong>STS</strong><br/><strong>Epigenetics</strong><br/><strong>Climate Crisis</strong><br/><strong>Feminist Movements</strong></p>
    </div>
  </Layout>
)

export default IndexPage
